var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    {
      scopedSlots: _vm._u([
        {
          key: "content",
          fn: function () {
            return undefined
          },
          proxy: true,
        },
        {
          key: "extraContent",
          fn: function () {
            return undefined
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "client-info" },
        [
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { title: "开发者信息" },
                    },
                    [
                      _c(
                        "a-descriptions",
                        {
                          attrs: {
                            bordered: "",
                            column: { xs: 1, sm: 1, md: 1, lg: 1 },
                          },
                        },
                        [
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "Client Id" } },
                            [_vm._v(" " + _vm._s(_vm.client.clientId) + " ")]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "Client Secret" } },
                            [
                              !_vm.clientSecret
                                ? _c(
                                    "a-button",
                                    {
                                      attrs: { type: "danger", ghost: "" },
                                      on: { click: _vm.restSecret },
                                    },
                                    [_vm._v("重置ClientSecret？")]
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(_vm.clientSecret)),
                                  ]),
                            ],
                            1
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "API Secret" } },
                            [
                              _vm._v(" " + _vm._s(_vm.client.apiSecret)),
                              _c("br"),
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "danger", ghost: "" },
                                  on: { click: _vm.restApiSecret },
                                },
                                [_vm._v("重置ApiSecret？")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "IP白名单" } },
                            [
                              !_vm.ipListEditMode
                                ? _c(
                                    "div",
                                    [
                                      _vm._v(
                                        _vm._s(_vm.client.whiteList) + "      "
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small",
                                            ghost: "",
                                          },
                                          on: { click: _vm.showIpListEditMode },
                                        },
                                        [_vm._v("修改白名单？")]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c("a-input", {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          placeholder: "请输入IP地址",
                                          allowClear: "",
                                        },
                                        model: {
                                          value: _vm.ipList,
                                          callback: function ($$v) {
                                            _vm.ipList = $$v
                                          },
                                          expression: "ipList",
                                        },
                                      }),
                                      _c("div", [
                                        _vm._v(
                                          "多个IP地址以半角逗号隔开，最多只支持设置5个IP地址。"
                                        ),
                                      ]),
                                      _c(
                                        "a-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: { click: _vm.updateIpList },
                                        },
                                        [_vm._v("确认修改")]
                                      ),
                                      _vm._v("    "),
                                      _c(
                                        "a-button",
                                        {
                                          attrs: { type: "primary", ghost: "" },
                                          on: { click: _vm.cancelUpdateIpList },
                                        },
                                        [_vm._v("取消")]
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
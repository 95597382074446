<template>
  <page-header-wrapper>
    <template v-slot:content>
    </template>
    <template v-slot:extraContent> </template>
    <div class="client-info">
      <a-row :gutter="24">
        <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <!-- 项目简介 -->
          <a-card style="width:100%" title="开发者信息">
            <a-descriptions bordered :column="{xs:1, sm:1, md:1, lg:1}">
              <a-descriptions-item label="Client Id">
                {{ client.clientId }}
              </a-descriptions-item>
              <a-descriptions-item label="Client Secret">
                <a-button type="danger" ghost @click="restSecret" v-if="!clientSecret">重置ClientSecret？</a-button>
                <span v-else>{{ clientSecret }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="API Secret">
                {{ client.apiSecret }}<br/>
                <a-button type="danger" ghost @click="restApiSecret">重置ApiSecret？</a-button>
              </a-descriptions-item>
              <a-descriptions-item label="IP白名单">
                <div v-if="!ipListEditMode">{{ client.whiteList }}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a-button type="primary" size="small" ghost @click="showIpListEditMode">修改白名单？</a-button>
                </div>
                <div v-else>
                  <a-input v-model="ipList" placeholder="请输入IP地址" allowClear style="width:300px;"/>
                  <div>多个IP地址以半角逗号隔开，最多只支持设置5个IP地址。</div>
                  <a-button type="primary" @click="updateIpList">确认修改</a-button>
                  &nbsp;&nbsp;
                  <a-button type="primary" @click="cancelUpdateIpList" ghost>取消</a-button>
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </page-header-wrapper>
</template>

<script>
// import { mapGetters } from 'vuex'
import { PageHeaderWrapper } from '@/components/MyLayout'
import { getMyClientInfo, resetClientSecret, resetApiSecret, updateIPList } from '@/api/openapi/client'

export default {
  name: 'Index',
  components: {
    PageHeaderWrapper
  },
  data() {
    return {
      client: {},
      clientSecret: null,
      ipList: '',
      ipListEditMode: false
    }
  },
  computed: {
  },
  created() {
    this.loadMyClientInfo()
  },
  mounted() {},
  methods: {
    loadMyClientInfo() {
      getMyClientInfo().then(response => {
        this.client = response.data
        this.ipList = response.data.whiteList
      })
    },
    restSecret() {
      const that = this
      this.$confirm({
        title: '确定要重置ClientSecret吗？',
        content: 'Secret重置后，旧的Secret将立即失效',
        onOk () {
          return resetClientSecret()
            .then(response => {
              const h = that.$createElement
              that.$success({
                  title: '重置成功，请保存好以下新的Secret：',
                  width: 700,
                  content: h('div', {}, [
                    h('p', response.data.secret)
                  ]),
                  onOk() {}
              })
              that.clientSecret = response.data.secret
            })
            .catch(err => {
              that.$error({
                title: '重置失败',
                content: err
              })
            })
            .finally(res => {
              that.loadMyClientInfo()
            })
        },
        onCancel () {

        }
      })
    },
    restApiSecret() {
      const that = this
      this.$confirm({
        title: '确定要重置ApiSecret吗？',
        content: 'Secret重置后，旧的Secret将立即失效',
        onOk () {
          return resetApiSecret()
            .then(response => {
              const h = that.$createElement
              that.$success({
                  title: '重置成功，请保存好以下新的ApiSecret：',
                  width: 700,
                  content: h('div', {}, [
                    h('p', response.data.secret)
                  ]),
                  onOk() {}
              })
              that.client.apiSecret = response.data.secret
            })
            .catch(err => {
              that.$error({
                title: '重置失败',
                content: err
              })
            })
            .finally(res => {
              that.loadMyClientInfo()
            })
        },
        onCancel () {

        }
      })
    },
    showIpListEditMode() {
      this.ipListEditMode = true
    },
    cancelUpdateIpList() {
      this.ipListEditMode = false
    },
    updateIpList() {
      const that = this
      this.$confirm({
        title: '',
        content: '确定修改IP白名单吗？',
        onOk () {
          return updateIPList({ ipList: that.ipList })
            .then(response => {
              that.$success({
                  title: '',
                  content: '修改成功',
                  onOk() {}
              })
              that.ipListEditMode = false
            })
            .catch(err => {
              that.$error({
                title: '修改失败',
                content: err
              })
            })
            .finally(res => {
              that.loadMyClientInfo()
            })
        },
        onCancel () {

        }
      })
    }
  }
}
</script>

<style lang="less">
.client-info {
  .ant-descriptions-item-label {
    width: 20%;
  }
}
</style>
